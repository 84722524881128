<template>
<body>
    <!-- ::::::  Start Header Section  ::::::  -->
    <Header />
    <!-- :::::: End Header Section ::::::  -->
    <!-- ::::::  Start  Breadcrumb Section  ::::::  -->
    <div class="page-breadcrumb">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <ul class="page-breadcrumb__menu">
                        <li class="page-breadcrumb__nav">
                            <router-link to="/">Home</router-link>
                        </li>
                        <li class="page-breadcrumb__nav active">Checkout</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <!-- ::::::  End  Breadcrumb Section  ::::::  -->
    <!-- ::::::  Start  Main Container Section  ::::::  -->
    <main id="main-container" class="main-container inner-page-container">
        <div class="container">
            <div class="row">
                <!-- Start Client Shipping Address -->
                <div class="col-lg-7">
                    <div class="row card pd-20 mb-3" v-if="addressDatas.length">
                        <div class="biling-item">
                            <div @click="open_dashboard2" class="justify-content-between d-flex " :class="dashboard_box == 'step-1' ? 'active' : ''">
                                <label id="toggle2" class="bold checkout-label">
                                    <i class="fa fa-check color-green" v-if="add_id>0" aria-hidden="true"></i>
                                    Select Address
                                </label>
                                <label v-if="dashboard_box == 'step-1'" class="pull-right">
                                    <i class="fa fa-eye font-26" aria-hidden="true"></i>
                                </label>
                                <label v-else class="pull-right">
                                    <i class="fa fa-eye-slash font-26" aria-hidden="true"></i>
                                </label>
                            </div>
                            <div class="billing-adress" id="open1" v-if="dashboard_box == 'step-1'">
                                <div class="contact-form form-style">
                                    <div class="row">
                                        <div :class="userAddress.shippingBilling==true ? 'col-md-12 address-label' : 'col-md-6 address-label'">
                                            <label>Select Shipping Address</label>
                                            <select v-model="add_id" @change="getSelectedAddress(add_id)" class="form-control">
                                                <option value="0">Select Shipping Address</option>
                                                <option :value="getShipping.add_id" v-for="getShipping in getShippingAddressData" :key="getShipping.add_id"> {{getShipping.add1}} </option>
                                            </select>
                                        </div>
                                        <div class="col-md-6 address-label" v-if="!userAddress.shippingBilling">
                                            <label>Select Billing Address</label>
                                            <select v-model="billing_id" @change="getSelectedBillingAddress(billing_id)" class="form-control">
                                                <option value="0">Select Billing address</option>
                                                <option :value="getShipping.add_id" v-for="getShipping in getBillingAddressData" :key="getShipping.add_id"> {{getShipping.add1}} </option>
                                            </select>
                                        </div>
                                        <div class="col-md-6" v-if="add_id != 0">
                                            <div class="col-md-12 card pd-20 shadow">
                                                <h5> Shipping Address </h5>
                                                <div class="col-md-12 foundation_sm">
                                                    <ul>
                                                        <li> {{selectedAddress.add_title}} </li>
                                                        <li><i class="fa fa-envelope-o" aria-hidden="true"></i>{{selectedAddress.email}}</li>
                                                        <li><i class="fa fa-phone" aria-hidden="true"></i> {{selectedAddress.mobile_no}} </li>
                                                        <li><i class="fi flaticon-pin" aria-hidden="true"></i> {{selectedAddress.add1}} </li>
                                                        <li> {{selectedAddress.add2}} </li>
                                                        <li> {{selectedAddress.city}}, {{selectedAddress.c_state}}, <br /> {{selectedAddress.country}}, {{selectedAddress.zipcode}} </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-6" v-if="!userAddress.shippingBilling && billing_id != 0">
                                            <div class="col-md-12 card pd-20 shadow ">
                                                <h5> Billing Address </h5>
                                                <div class="col-md-12 foundation_sm">
                                                    <ul>
                                                        <li> {{selectedBillingAddress.add_title}} </li>
                                                        <li><i class="fa fa-envelope-o" aria-hidden="true"></i>{{selectedBillingAddress.email}}</li>
                                                        <li><i class="fa fa-phone" aria-hidden="true"></i> {{selectedBillingAddress.mobile_no}} </li>
                                                        <li><i class="fi flaticon-pin" aria-hidden="true"></i> {{selectedBillingAddress.add1}} </li>
                                                        <li> {{selectedBillingAddress.add2}} </li>
                                                        <li> {{selectedBillingAddress.city}}, {{selectedBillingAddress.c_state}}, <br /> {{selectedBillingAddress.country}}, {{selectedBillingAddress.zipcode}} </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-12 col-md-12 col-12 select-bill" >
                                            <input type="checkbox" placeholder="" style="margin: 10px 30px 0px 0px;" @change="checkBillingShipping" v-model="userAddress.shippingBilling" id="sel" name="sel">
                                            <label for="sel">Select Shipping address as billing address</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ValidationObserver ref="form">
                        <div class="row theme-card pd-20">
                            <div class="section-content">
                                <h5 class="section-content__title">Shipping Details</h5>
                            </div>
                            <div class="col-md-6">
                                <div class="form-box__single-group">
                                    <label for="form-first-name">Fullname</label>
                                    <validation-provider rules="required|alpha_spaces|min:5" v-slot="{ errors }">
                                        <input type="text" v-model="userAddress.add_title" placeholder="Enter your fullname..." id="form-first-name" name="Fullname">
                                        <span class="error-msgs">{{ errors[0] }}</span>
                                    </validation-provider>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-box__single-group">
                                    <label for="form-phone">Mobile</label>
                                    <validation-provider rules="required|integer|min:10|max:10" v-slot="{ errors }">
                                        <input type="text" placeholder="Enter your mobile number..." v-model="userAddress.mobile_no" id="form-phone" name="Number">
                                        <span class="error-msgs">{{ errors[0] }}</span>
                                    </validation-provider>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-box__single-group">
                                    <label for="form-email">Email Address</label>
                                    <validation-provider rules="required|email" v-slot="{ errors }">
                                        <input type="text" placeholder="Enter your email address" v-model="userAddress.email" id="form-email" name="email">
                                        <span class="error-msgs">{{ errors[0] }}</span>
                                    </validation-provider>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-box__single-group">
                                    <label for="form-country">* Country</label>
                                    <select name="address" v-model="userAddress.country" id="form-country">
                                        <option value="india" selected>India</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-box__single-group">
                                    <label for="form-state">* Region / State</label>
                                    <validation-provider rules="required|alpha_spaces|min:5" v-slot="{ errors }">
                                        <input type="text" placeholder="Enter your state..." v-model="userAddress.c_state" id="form-state" name="state">
                                        <span class="error-msgs">{{ errors[0] }}</span>
                                    </validation-provider>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-box__single-group">
                                    <label for="form-state">* City</label>
                                    <validation-provider rules="required|alpha_spaces|min:3" v-slot="{ errors }">
                                        <input type="text" placeholder="Enter your city..." v-model="userAddress.city" id="City" name="City">
                                        <span class="error-msgs">{{ errors[0] }}</span>
                                    </validation-provider>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-box__single-group">
                                    <label for="form-address-1">Address Line 1</label>
                                    <validation-provider rules="required|min:3" v-slot="{ errors }">
                                        <input type="text" placeholder="Enter your address" v-model="userAddress.add1" id="form-address-1" name="Address line 1">
                                        <span class="error-msgs">{{ errors[0] }}</span>
                                    </validation-provider>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-box__single-group">
                                    <label for="form-address-1">Address Line 2</label>
                                    <validation-provider rules="required|min:3" v-slot="{ errors }">
                                        <input type="text" placeholder="Enter address line 2" v-model="userAddress.add2" id="form-address-2" name="Address line 1">
                                        <span class="error-msgs">{{ errors[0] }}</span>
                                    </validation-provider>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-box__single-group">
                                    <label for="form-zipcode">* Pin Code</label>
                                    <validation-provider rules="required|integer|min:6|max:6" v-slot="{ errors }">
                                        <input type="text" placeholder="Enter your pincode..." v-model="userAddress.zipcode" id="form-zipcode" name="Pincode">
                                        <span class="error-msgs">{{ errors[0] }}</span>
                                    </validation-provider>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-box__single-group col-sm-12 col-xs-12 col-md-6 col-lg-3">
                                    <button type="button" @click="switchLoc" class="btn btn--small btn--radius btn--green btn--green-hover-black btn--uppercase font--bold">Continue</button>
                                </div>
                            </div>
                        </div>
                    </ValidationObserver>
                    <div class="row ">
                        <div class="col-md-12">
                            <div class="m-tb-20">
                                <label for="shipping-account">
                                    <input type="checkbox" name="check-account" class="shipping-account" id="shipping-account">
                                    <span>Bill to a different address?</span>
                                </label>
                                <div class="toogle-form my-3">
                                    <ValidationObserver ref="formBilling">
                                        <div class="row theme-card pd-20">
                                            <div class="section-content">
                                                <h5 class="section-content__title">Billing Details</h5>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-box__single-group">
                                                    <label for="form-first-name">Fullname</label>
                                                    <validation-provider rules="required|alpha_spaces|min:5" v-slot="{ errors }">
                                                        <input type="text" v-model="userAddressBilling.add_title" placeholder="Enter your fullname..." id="form-first-name" name="Fullname">
                                                        <span class="error-msgs">{{ errors[0] }}</span>
                                                    </validation-provider>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-box__single-group">
                                                    <label for="form-phone">Mobile</label>
                                                    <validation-provider rules="required|integer|min:10|max:10" v-slot="{ errors }">
                                                        <input type="text" placeholder="Enter your mobile number..." v-model="userAddressBilling.mobile_no" id="form-phone" name="Number">
                                                        <span class="error-msgs">{{ errors[0] }}</span>
                                                    </validation-provider>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-box__single-group">
                                                    <label for="form-email">Email Address</label>
                                                    <validation-provider rules="required|email" v-slot="{ errors }">
                                                        <input type="text" placeholder="Enter your email address" v-model="userAddressBilling.email" id="form-email" name="email">
                                                        <span class="error-msgs">{{ errors[0] }}</span>
                                                    </validation-provider>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-box__single-group">
                                                    <label for="form-country">* Country</label>
                                                    <select name="address" v-model="userAddressBilling.country" id="form-country">
                                                        <option value="india" selected>India</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-box__single-group">
                                                    <label for="form-state">* Region / State</label>
                                                    <validation-provider rules="required|alpha_spaces|min:5" v-slot="{ errors }">
                                                        <input type="text" placeholder="Enter your state..." v-model="userAddressBilling.c_state" id="form-state" name="state">
                                                        <span class="error-msgs">{{ errors[0] }}</span>
                                                    </validation-provider>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-box__single-group">
                                                    <label for="form-state">* City</label>
                                                    <validation-provider rules="required|alpha_spaces|min:3" v-slot="{ errors }">
                                                        <input type="text" placeholder="Enter your city..." v-model="userAddressBilling.city" id="City" name="City">
                                                        <span class="error-msgs">{{ errors[0] }}</span>
                                                    </validation-provider>
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="form-box__single-group">
                                                    <label for="form-address-1">Address Line 1</label>
                                                    <validation-provider rules="required|min:3" v-slot="{ errors }">
                                                        <input type="text" placeholder="Enter your address" v-model="userAddressBilling.add1" id="form-address-1" name="Address line 1">
                                                        <span class="error-msgs">{{ errors[0] }}</span>
                                                    </validation-provider>
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="form-box__single-group">
                                                    <label for="form-address-1">Address Line 2</label>
                                                    <validation-provider rules="required|min:3" v-slot="{ errors }">
                                                        <input type="text" placeholder="Enter address line 2" v-model="userAddressBilling.add2" id="form-address-2" name="Address line 1">
                                                        <span class="error-msgs">{{ errors[0] }}</span>
                                                    </validation-provider>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-box__single-group">
                                                    <label for="form-zipcode">* Pin Code</label>
                                                    <validation-provider rules="required|integer|min:6|max:6" v-slot="{ errors }">
                                                        <input type="text" placeholder="Enter your pincode..." v-model="userAddressBilling.zipcode" id="form-zipcode" name="Pincode">
                                                        <span class="error-msgs">{{ errors[0] }}</span>
                                                    </validation-provider>
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="form-box__single-group col-sm-12 col-xs-12 col-md-6 col-lg-3">
                                                    <button type="button" @click="switchLocBilling" class="btn btn--small btn--radius btn--green btn--green-hover-black btn--uppercase font--bold">Continue</button>
                                                </div>
                                            </div>
                                        </div>
                                    </ValidationObserver>
                                </div>
                            </div>
                        </div> <!-- End Another Shipping Address -->
                    </div>
                    <!-- </form> -->
                </div> <!-- End Client Shipping Address -->
                <!-- Start Order Wrapper -->
                <div class="col-lg-5">
                    <div class="your-order-section">
                        <div class="section-content">
                            <h5 class="section-content__title">Your order</h5>
                        </div>
                        <div class="your-order-box gray-bg m-t-40 m-b-30">
                            <div class="your-order-product-info">
                                <div class="your-order-top d-flex justify-content-between">
                                    <h6 class="your-order-top-left font--bold">Product</h6>
                                    <h6 class="your-order-top-right font--bold">Total</h6>
                                </div>
                                <ul class="your-order-middle">
                                    <template v-for="(cart,key) in cartCount.data">
                                        <li :key="key" class="d-flex justify-content-between">
                                            <span class="your-order-middle-left font--semi-bold">Product Name X {{cart.qty}}</span>
                                            <span class="your-order-middle-right font--semi-bold"> INR {{cart.selling_cost*cart.qty}} </span>
                                        </li>
                                    </template>
                                </ul>
                                <div class="your-order-bottom d-flex justify-content-between" v-if="cartCount.total_amount>=min">
                                    <h6 class="your-order-bottom-left font--bold">Shipping</h6>
                                    <span class="your-order-bottom-right font--semi-bold">INR {{shipping}}</span>
                                </div>
                                <div class="your-order-total d-flex justify-content-between">
                                    <h5 class="your-order-total-left font--bold">Total</h5>
                                    <h5 class="your-order-total-right font--bold" v-if="cartCount.total_amount>=min"> INR {{parseFloat(cartCount.total_amount+shipping) + parseFloat((myCouponMoney / 100) * cartCount.total_amount) }} </h5>
                                    <h5 class="your-order-total-right font--bold" v-else> INR {{parseFloat(cartCount.total_amount) + parseFloat((myCouponMoney / 100) * cartCount.total_amount)}} </h5>
                                </div>
                            </div>
                        </div>
                        <div class="row">

                            <div class="col-lg-12 col-md-12 col-12">
                                <p class="text-center"> <i class="fa fa-shield text-success"></i> Safe and Secure Payments. Easy returns. 100% Authentic products.</p>
                                <img src="../assets/img/payment.png" />
                                <br />
                                <br />
                                <div class="radio-btn mb-3 mx-3">
                                    <div class="">
                                        <input type="radio" class="shipping-select " name="payment" v-model="paymentMethod" value="1" style="margin: 0 35px 0 0;" id="paymentMethod1" >
                                        <label class="label mr-10" for="paymentMethod1">Cash On Delivery</label>
                                    </div>
                                    <br />
                                    <div>
                                        <input type="radio" class="shipping-select " name="payment" v-model="paymentMethod" value="2" style="margin: 0 35px 0 0;" id="paymentMethod2" >
                                        <label class="label mr-10" for="paymentMethod2">Credit / Debit Card</label>
                                    </div>
                                </div>
                            </div>
                            <button class="btn btn--small btn--radius btn--green btn--green-hover-black btn--uppercase font--bold mx-3" @click="orderGenerate" type="button">PLACE ORDER</button>
                        </div>
                    </div>
                </div> <!-- End Order Wrapper -->
            </div>
        </div>

        <div class="d-none hide">
            <form method="post" :action="$root.URL_ROOT+'payment.php'">
                <input type="hidden" name="amount" id="p_amount">
                <input type="hidden" name="oid" id="p_oid">
                <input type="hidden" name="uid" id="p_uid">
                <input type="hidden" name="redirect" :value="$root.Home_link" >
                <input type="submit" id="pbtn">

            </form>
        </div>


    </main> <!-- ::::::  End  Main Container Section  ::::::  -->
    <!-- ::::::  Start  Footer ::::::  -->
    <Footer />
    <!-- ::::::  End  Footer ::::::  -->
</body>
</template>

<script>
import axios from "axios";
import Header from "@/components/navbar";
import Footer from "@/components/footer";
import {
    ValidationProvider,
    ValidationObserver
} from "vee-validate";

export default {
    props: ["catedatas"],
    components: {
        Header,
        Footer,
        ValidationProvider,
        ValidationObserver
    },
    data() {
        return {
         
            add_id: 0,
            billing_id: 0,
            paymentMethod: 1,
            couponCode: '',
            getCounts: '',
            myCouponMoney: localStorage.getItem('coupon') ? localStorage.getItem('coupon') : 0,
            isHas: false,
            isAdd: 0,
            shipping: 0,
            min: 0,
            dashboard_box: "step-1",
            ip_address: JSON.parse(localStorage.getItem("ip_address")),
            uid: localStorage.getItem("user"),
            userData: [],
            cartCount: [],
            amount: 100,
            addressDatas: [],
            paymenttype: 'ppd',
            awbNumber: '',
            selectedAddress: [],
            getShippingAddressData: [],
            getBillingAddressData: [],
            selectedBillingAddress: [],
            userAddress: {
                add_title: '',
                mobile_no: "",
                add1: "",
                add2: "",
                c_state: "",
                city: "",
                zipcode: "",
                add_type: 'Shipping',
                shippingBilling: false
            },
            userAddressBilling: {
                add_title: '',
                mobile_no: "",
                add1: "",
                add2: "",
                c_state: "",
                city: "",
                zipcode: "",
                add_type: '',
                country: 'india',
            },
            

        };
    },
    watch: {
        add_id: function (id) {
            if (this.add_id > 0) {
                this.getSelectedAddress(id);
            }
        },
        billing_id: function (id) {
            if (this.billing_id > 0) {
                this.getSelectedBillingAddress(this.billing_id);
            }
        },
        cartCount: function () {
            if (this.cartCount.count == 0) {
                this.$toasted.global.error({
                    message: 'Your cart is empty please add some product'
                });
            }
        }
    },
    mounted() {
        this.includeStripe('checkout.razorpay.com/v1/checkout.js');
        this.uid = localStorage.getItem("user");
        (this.ip_address = JSON.parse(localStorage.getItem("ip_address"))),
        this.userDetail()
        this.getAddress();
        this.getState();
        var ev = this
        if (this.add_id > 0) {
            this.getSelectedAddress(this.add_id);
        }
        setTimeout(function () {
            ev.getSelectedAddress(this.add_id);
        }, 2000);
        this.goToTop();
        this.getCart();
        this.shippingCharge()
        this.checkfirstSigup()
        this.getShippingAddress()
        this.getBillingAddress()
    },
    methods: {
        getShippingAddress() {
            var user_session = JSON.parse(localStorage.getItem("user"));
            var uid = "";
            if (user_session != null) {
                uid = user_session;
            } else {
                uid = null;
            }
            var ev = this;
            axios({
                method: "POST",
                url: this.$root.URL_ROOT + "api.php",
                data: {
                    type: "getShippingAddress",
                    uid: uid,
                },
            }).then(function (response) {
                var data = response.data;
                console.log('getShippingAddress', data);
                ev.getShippingAddressData = data;
            });
        },
        getBillingAddress() {
            var user_session = JSON.parse(localStorage.getItem("user"));
            var uid = "";
            if (user_session != null) {
                uid = user_session;
            } else {
                uid = null;
            }
            var ev = this;
            axios({
                method: "POST",
                url: this.$root.URL_ROOT + "api.php",
                data: {
                    type: "getBillingAddress",
                    uid: uid,
                },
            }).then(function (response) {
                var data = response.data;
                ev.getBillingAddressData = data;
            });
        },
        getSelectedBillingAddress(id) {
            var user_session = JSON.parse(localStorage.getItem("user"));
            var uid = "";
            if (user_session != null) {
                uid = user_session;
            } else {
                uid = null;
            }
            var ev = this;
            axios({
                method: "POST",
                url: this.$root.URL_ROOT + "api.php",
                data: {
                    type: "getSelectedBillingAddress",
                    uid: uid,
                    aid: id,
                },
            }).then(function (response) {
                var data = response.data;
                ev.selectedBillingAddress = data;
                ev.billing_id = data.add_id;
            });
        },
        getSelectedAddress() {
            var user_session = JSON.parse(localStorage.getItem("user"));
            var uid = "";
            if (user_session != null) {
                uid = user_session;
            } else {
                uid = null;
            }
            var ev = this;
            axios({
                method: "POST",
                url: this.$root.URL_ROOT + "api.php",
                data: {
                    type: "getSelectedAddress",
                    uid: uid,
                    aid: ev.add_id,
                },
            }).then(function (response) {
                var data = response.data;
                ev.selectedAddress = data;
                ev.add_id = data.add_id;

            });
        },
        checkfirstSigup() {
            var self = this
            axios({
                method: "POST",
                url: this.$root.URL_ROOT + "api.php",
                data: {
                    type: "checkFirstSignup",
                    uid: self.uid,
                },
            }).then(function (response) {
                var data = response.data;
                // console.log(data);
                if (data.data) {

                } else {
                    self.couponCode = 'FIRST'
                    self.applyCoupon()
                }
            });
        },
        getCount(id) {
            var count = ''
            var data = count.push(id);
            this.getCounts = data
        },
        applyCoupon() {
            var self = this
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'api.php',
                data: {
                    type: 'couponapply',
                    coupon: self.couponCode,
                    uid: self.uid
                }
            }).then(function (response) {
                var data = response.data
                if (data.status == "success") {
                    self.myCouponMoney = data.data
                    localStorage.setItem('coupon', data.data)
                    localStorage.setItem('coupon_name', self.couponCode)
                    self.$toasted.global.success({
                        message: data.msg
                    });
                } else {
                    self.$toasted.global.error({
                        message: data.msg
                    });
                }
            })
        },
        switchLoc() {
            var iv = this
            var vali = this.$refs.form.validate();
            vali.then(val => {
                    if (val == true) {
                        iv.save_address()
                    }
                }

            )
        },
        switchLocBilling() {
            var iv = this
            var vali = this.$refs.formBilling.validate();
            vali.then(val => {
                    if (val == true) {
                        iv.save_billing_address()
                    }
                }

            )
        },
        add_select() {
            if (this.add_id > 0) {
                this.dashboard_box = 'step-3';
            } else {
                this.$toasted.global.error({
                    message: "Please select address "
                });
            }
        },
        includeStripe(URL, callback) {
            let documentTag = document,
                tag = 'script',
                object = documentTag.createElement(tag),
                scriptTag = documentTag.getElementsByTagName(tag)[0];
            object.src = 'https://' + URL;
            if (callback) {
                object.addEventListener('load', function (e) {
                    callback(null, e);
                }, false);
            }
            scriptTag.parentNode.insertBefore(object, scriptTag);
        },
        makePayment: function (ev) {
            if (this.add_id == 0) {
                this.$toasted.global.error({
                    message: 'Please add address'
                });
            } else {
                var ev = this
                var getcouponMoney = parseFloat(this.myCouponMoney)
                if (ev.min > this.cartCount.total_amount) {
                    this.shipping = 0
                }

                // var amount = parseFloat((this.cartCount.total_amount + this.shipping) - getcouponMoney)

                // var amount = parseFloat(this.cartCount.total_amount + this.shipping - getcouponMoney)

                var amount = parseFloat(this.cartCount.total_amount + this.shipping) - parseFloat((this.myCouponMoney / 100) * this.cartCount.total_amount)
                //ev.p_amount1=amount;
                var oid = localStorage.getItem('oid');
                var uid = localStorage.getItem('user');
                document.getElementById("p_oid").value=oid;
            // document.getElementById("p_user").value=uid;
                document.getElementById("p_amount").value=amount;
                document.getElementById("pbtn").click();



                // console.log(this.shipping);
                // console.log(this.cartCount.total_amount);
                // console.log(this.shipping);
                // console.log(getcouponMoney)


                // var rzp1 = new Razorpay({
                //     key: 'rzp_test_MBSCGzeRjYUhaT',
                //     amount: amount * 100,
                //     currency: "INR",
                //     "handler": function (response) {
                //         ev.pay_id = response.razorpay_payment_id
                //         localStorage.setItem('successData', response)
                //         ev.updatePayment(response);
                //     },
                //     "ondismiss": function () {
                //         window.location.reload();
                //     },
                //     prefill: {},
                //     notes: {
                //         // address: this.address, //this.$store.state.profile.address
                //     },
                //     theme: {
                //         color: "#00FFFF"
                //     },
                //     //order_id: 1,
                //     redirect: false
                // });
                // rzp1.open();
            }

        },
        updatePayment(payment_detail) {
            var self = this;
            var oid = localStorage.getItem('oid');
            var uid = localStorage.getItem('user');
            var coupon_name = localStorage.getItem('coupon_name')
            var couponMoney = localStorage.getItem('coupon')
            axios.post(this.$root.URL_ROOT + "api.php", {
                type: 'payment_success',
                uid: uid,
                oid: oid,
                payment_detail: payment_detail,
                coupon_name: coupon_name,
                coupon: couponMoney,
            }).then(function (response) {
                var data = response.data
                if (data.status == 'success') {
                    if (self.paymentMethod == 1) {
                        self.$router.push({
                            name: 'success_cod'
                        });
                    } else {
                        self.$router.push({
                            name: 'success'
                        });
                    }

                }
            });
        },
        goToTop() {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        },
        open_dashboard(vl) {
            this.dashboard_box = vl;
            window.scrollBy(100, 0);

        },
        open_dashboard2() {
            if (this.dashboard_box == 'step-1') {
                this.dashboard_box = 'step-3'
            } else {
                this.dashboard_box = 'step-1'
            }
        },
        userDetail() {
            var ev = this;
            axios({
                method: "POST",
                url: this.$root.URL_ROOT + "api.php",
                data: {
                    type: "userDetail",
                    uid: ev.uid
                },
            }).then(function (response) {
                var data = response.data;
                if (data.data) {
                    ev.userData = data.data
                } else {
                    ev.logout()
                    ev.$toasted.global.error({
                        message: 'Your Account is Deactivated'
                    })
                    setTimeout(function () {
                        ev.$router.push({
                            name: 'Home'
                        });
                    }, 1000);
                }
            });
        },
        orderGenerate() {
            var ev = this
            var paymentType = 'Online'
            if (this.paymentMethod == 1) {
                paymentType = 'COD'
            }
            var user_session = localStorage.getItem("user");
            var uid = "";
            if (user_session != null) {
                uid = user_session;
            } else {
                uid = null;
            }
            var gst = this.cartCount.total_gst;
            if (this.cartCount.total_amount >= this.min) {
                var amount = parseFloat(this.cartCount.total_amount + this.shipping) - parseFloat((this.myCouponMoney / 100) * this.cartCount.total_amount)
                var shipping = this.shipping;
            } else {
                var amount = parseFloat(this.cartCount.total_amount) - parseFloat((this.myCouponMoney / 100) * this.cartCount.total_amount)
                var shipping = 0;
            }
            console.log(amount);
            axios({
                method: "POST",
                url: this.$root.URL_ROOT + "api.php",
                data: {
                    type: "order",
                    uid: uid,
                    address_id: ev.add_id,
                    billing_id: ev.billing_id,
                    total: amount,
                    payment_type: paymentType,
                    coupon: ev.myCouponMoney,
                    shipping_amount: shipping,
                    gst: gst,
                },
            }).then(function (response) {
                var data = response.data;
                if (data.status == "success") {
                    localStorage.setItem('oid', data.oid)
                    ev.isAdd = data.add_id
                    if (ev.paymentMethod == 2) {
                        ev.makePayment()
                    } else {
                        ev.updatePayment()
                    }
                    ev.open_dashboard("step-3");
                    ev.getAddress();
                }
            });
        },
        getAddress() {
            var ip_address = JSON.parse(localStorage.getItem("ip_address"));
            var user_session = JSON.parse(localStorage.getItem("user"));
            var uid = "";
            if (user_session != null) {
                uid = user_session;
            } else {
                uid = null;
            }
            var ev = this;
            axios({
                method: "POST",
                url: this.$root.URL_ROOT + "api.php",
                data: {
                    type: "getAllAddress",
                    uid: uid,
                    ip_address: ip_address,
                },
            }).then(function (response) {
                var data = response.data;
                if (data.status == "success") {
                    ev.addressDatas = data.data;
                    if (ev.addressDatas.length == 0) {
                        ev.dashboard_box = "step-2"
                    }
                    ev.add_id = ev.addressDatas[0].add_id
                    ev.billing_id = ev.addressDatas[0].add_id
                    ev.getSelectedAddress(ev.addressDatas[0].add_id);
                }
            });
        },
        save_address() {
            var ev = this;
            if (this.userAddress.add_title == "") {
                ev.$toasted.global.error({
                    message: "please enter FullName "
                });
            } else if (this.userAddress.mobile_no == "") {
                ev.$toasted.global.error({
                    message: "please enter mobile number "
                });
            } else if (this.userAddress.email == "") {
                ev.$toasted.global.error({
                    message: "please enter Email "
                });
            } else if (this.userAddress.add1 == "") {
                ev.$toasted.global.error({
                    message: "please enter address 1 "
                });
            } else if (this.userAddress.city == "") {
                ev.$toasted.global.error({
                    message: "please enter city "
                });
            } else if (this.userAddress.c_state == "") {
                ev.$toasted.global.error({
                    message: "please select state "
                });
            } else if (this.userAddress.add1 == "") {
                ev.$toasted.global.error({
                    message: "please Enter address line 1 "
                });
            } else if (this.userAddress.add2 == "") {
                ev.$toasted.global.error({
                    message: "please Enter address line 2"
                });
            } else if (this.userAddress.zipcode == "") {
                ev.$toasted.global.error({
                    message: "please Enter zipcode"
                });
            } else {
                var user_session = localStorage.getItem("user");
                var uid = "";
                if (user_session != null) {
                    uid = user_session;
                } else {
                    uid = null;
                }
                if (ev.userAddress.shippingBilling == true) {
                    ev.userAddress.add_type = 'Both'
                }
                // console.log(ev.userAddress.shippingBilling);
                axios({
                    method: "POST",
                    url: this.$root.URL_ROOT + "api.php",
                    data: {
                        type: "add_address",
                        uid: uid,
                        add_title: ev.userAddress.add_title,
                        city: ev.userAddress.city,
                        email: ev.userAddress.email,
                        mobile: ev.userAddress.mobile_no,
                        add1: ev.userAddress.add1,
                        add2: ev.userAddress.add2,
                        pincode: ev.userAddress.zipcode,
                        state: ev.userAddress.c_state,
                        country: ev.userAddress.country,
                        add_type: ev.userAddress.add_type,
                    },
                }).then(function (response) {
                    var data = response.data;
                    // console.log('add_address', response);
                    if (data.status == "success") {
                        ev.$toasted.global.success({
                            message: data.msg
                        });
                        ev.add_id = data.add_id
                        ev.isAdd = data.add_id
                        ev.open_dashboard("step-3");
                        ev.getAddress();
                    }
                });
            }
        },
        save_billing_address() {
            var ev = this;
            if (this.userAddressBilling.add_title == "") {
                ev.$toasted.global.error({
                    message: "please enter FullName "
                });
            } else if (this.userAddressBilling.mobile_no == "") {
                ev.$toasted.global.error({
                    message: "please enter mobile number "
                });
            } else if (this.userAddressBilling.email == "") {
                ev.$toasted.global.error({
                    message: "please enter Email "
                });
            } else if (this.userAddressBilling.add1 == "") {
                ev.$toasted.global.error({
                    message: "please enter address 1 "
                });
            } else if (this.userAddressBilling.city == "") {
                ev.$toasted.global.error({
                    message: "please enter city "
                });
            } else if (this.userAddressBilling.c_state == "") {
                ev.$toasted.global.error({
                    message: "please select state "
                });
            } else if (this.userAddressBilling.add1 == "") {
                ev.$toasted.global.error({
                    message: "please Enter address line 1 "
                });
            } else if (this.userAddressBilling.add2 == "") {
                ev.$toasted.global.error({
                    message: "please Enter address line 2"
                });
            } else if (this.userAddressBilling.zipcode == "") {
                ev.$toasted.global.error({
                    message: "please Enter zipcode"
                });
            } else {
                var user_session = localStorage.getItem("user");
                var uid = "";
                if (user_session != null) {
                    uid = user_session;
                } else {
                    uid = null;
                }

                axios({
                    method: "POST",
                    url: this.$root.URL_ROOT + "api.php",
                    data: {
                        type: "add_address",
                        uid: uid,
                        add_title: ev.userAddressBilling.add_title,
                        city: ev.userAddressBilling.city,
                        email: ev.userAddressBilling.email,
                        mobile: ev.userAddressBilling.mobile_no,
                        add1: ev.userAddressBilling.add1,
                        add2: ev.userAddressBilling.add2,
                        pincode: ev.userAddressBilling.zipcode,
                        state: ev.userAddressBilling.c_state,
                        country: ev.userAddressBilling.country,
                        add_type: 'Billing',
                    },
                }).then(function (response) {
                    var data = response.data;
                    // console.log('add_address', response);
                    if (data.status == "success") {
                        ev.$toasted.global.success({
                            message: data.msg
                        });
                        ev.add_id = data.add_id
                        ev.isAdd = data.add_id
                        ev.open_dashboard("step-3");
                        ev.getAddress();
                    }
                });
            }
        },
        getState() {
            var ev = this;
            axios({
                method: "POST",
                url: this.$root.URL_ROOT + "api.php",
                data: {
                    type: "getState",
                },
            }).then(function (response) {
                var data = response.data;
                ev.stateData = data;
            });
        },
        checked_add(id) {
            this.aid = id;
            this.getSelectedAddress();
            this.address_box = true;
        }
    },
};
</script><script>
import axios from "axios";
import Header from "@/components/navbar";
import Footer from "@/components/footer";
import {
    ValidationProvider,
    ValidationObserver
} from "vee-validate";

export default {
    props: ["catedatas"],
    components: {
        Header,
        Footer,
        ValidationProvider,
        ValidationObserver
    },
    data() {
        return {
            FinalTotalAmount: 0,
            add_id: 0,
            cgstLess: 0,
            sgstLess: 0,
            withTextAmount: 0,
            withoutTextAmount: 0,
            totalwithoutgst: 0,
            ttotalwithoutgst: 0,
            billing_id: 0,
            paymentMethod: 1,
            couponCode: '',
            getCounts: '',
            myCouponMoney: localStorage.getItem('coupon') ? localStorage.getItem('coupon') : 0,
            isHas: false,
            isAdd: 0,
            shipping: 0,
            min: 0,
            dashboard_box: "step-1",
            ip_address: JSON.parse(localStorage.getItem("ip_address")),
            uid: localStorage.getItem("user"),
            userData: [],
            cartCount: [],
            amount: 100,
            addressDatas: [],
            paymenttype: 'ppd',
            awbNumber: '',
            selectedAddress: [],
            getShippingAddressData: [],
            getBillingAddressData: [],
            selectedBillingAddress: [],
            userAddress: {
                add_title: '',
                mobile_no: "",
                add1: "",
                add2: "",
                c_state: "",
                city: "",
                zipcode: "",
                add_type: 'Shipping',
                shippingBilling: false
            },
            userAddressBilling: {
                add_title: '',
                mobile_no: "",
                add1: "",
                add2: "",
                c_state: "",
                city: "",
                zipcode: "",
                add_type: '',
            }

        };
    },
    watch: {
        add_id: function (id, oldId) {
            if (this.add_id > 0 && this.add_id != oldId) {
                this.getSelectedAddress(id);
                // if(this.userAddress.shippingBilling==true){
                //      this.billing_id = this.add_id
                // }
            }
        },
        billing_id: function (id, oldId) {
            if (this.billing_id > 0 && this.billing_id != oldId) {
                this.getSelectedBillingAddress(id);
            }
        },
        // userAddress: function(id){
        //     if(this.userAddress.shippingBilling==true){
        //         this.billing_id = this.add_id
        //     }
        // },
        //         selectedAddress: function(){
        //  if (this.add_id > 0) {
        //             this.getSelectedAddress(this.add_id);
        //         }
        //         },
        cartCount: function () {
            if (this.cartCount.count == 0) {
                this.$toasted.global.error({
                    message: 'Your cart is empty please add some product'
                });
            }
        }
    },
    mounted() {
        this.includeStripe('checkout.razorpay.com/v1/checkout.js');
        this.uid = localStorage.getItem("user");
        (this.ip_address = JSON.parse(localStorage.getItem("ip_address"))),
        this.userDetail()
        this.getAddress();
        this.getState();
        var ev = this
        if (this.add_id > 0) {
            this.getSelectedAddress(this.add_id);
        }
        setTimeout(function () {
            ev.getSelectedAddress(this.add_id);
            // ev.getSelectedBillingAddress(this.billing_id);
        }, 2000);
        this.goToTop();
        this.getCart();
        this.shippingCharge()
        this.checkfirstSigup()
        this.getShippingAddress()
        this.getBillingAddress()
    },
    methods: {
        checkBillingShipping() {
            if (this.userAddress.shippingBilling == true) {
                this.billing_id = this.add_id
            }
        },
        getShippingAddress() {
            var user_session = JSON.parse(localStorage.getItem("user"));
            var uid = "";
            if (user_session != null) {
                uid = user_session;
            } else {
                uid = null;
            }
            var ev = this;
            axios({
                method: "POST",
                url: this.$root.URL_ROOT + "api.php",
                data: {
                    type: "getShippingAddress",
                    uid: uid,
                },
            }).then(function (response) {
                var data = response.data;
                console.log('getShippingAddress', data);
                ev.add_id = data[0].add_id;
                ev.getShippingAddressData = data;
            });
        },
        getBillingAddress() {
            var user_session = JSON.parse(localStorage.getItem("user"));
            var uid = "";
            if (user_session != null) {
                uid = user_session;
            } else {
                uid = null;
            }
            var ev = this;
            axios({
                method: "POST",
                url: this.$root.URL_ROOT + "api.php",
                data: {
                    type: "getBillingAddress",
                    uid: uid,
                },
            }).then(function (response) {
                var data = response.data;
                // ev.add_id = ev.addressDatas[0].add_id
                ev.billing_id = data[0].add_id
                ev.getBillingAddressData = data;
            });
        },
        getSelectedBillingAddress(id) {
            if (id == 0) {

            } else {
                var user_session = JSON.parse(localStorage.getItem("user"));
                var uid = "";
                if (user_session != null) {
                    uid = user_session;
                } else {
                    uid = null;
                }
                var ev = this;
                axios({
                    method: "POST",
                    url: this.$root.URL_ROOT + "api.php",
                    data: {
                        type: "getSelectedBillingAddress",
                        uid: uid,
                        aid: id,
                    },
                }).then(function (response) {
                    var data = response.data;
                    ev.selectedBillingAddress = data;
                    ev.billing_id = data.add_id;
                });
            }
        },
        getSelectedAddress() {
            if (this.add_id == 0) {

            } else {
                var user_session = JSON.parse(localStorage.getItem("user"));
                var uid = "";
                if (user_session != null) {
                    uid = user_session;
                } else {
                    uid = null;
                }
                var ev = this;
                axios({
                    method: "POST",
                    url: this.$root.URL_ROOT + "api.php",
                    data: {
                        type: "getSelectedAddress",
                        uid: uid,
                        aid: ev.add_id,
                    },
                }).then(function (response) {
                    var data = response.data;
                    ev.selectedAddress = data;
                    ev.add_id = data.add_id;

                });
            }
        },
        checkfirstSigup() {
            var self = this
            axios({
                method: "POST",
                url: this.$root.URL_ROOT + "api.php",
                data: {
                    type: "checkFirstSignup",
                    uid: self.uid,
                },
            }).then(function (response) {
                var data = response.data;
                // console.log(data);
                if (data.data) {

                } else {
                    self.couponCode = 'FIRST'
                    self.applyCoupon()
                }
            });
        },
        getCount(id) {
            var count = ''
            var data = count.push(id);
            this.getCounts = data
        },
        generateAwbNumber() {
            var self = this
            var oid = localStorage.getItem('oid')
            if (this.paymentMethod == 1) {
                self.paymenttype = "COD"
            }
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'api.php',
                data: {
                    type: 'generateAwb',
                    payment: self.paymenttype,
                    oid: oid
                }
            }).then(function (response) {
                var data = response.data
                // console.log('generateAwb',data)
                if (data.status == "success") {
                    self.awbNumber = data.data
                    self.createShipping()
                }
            })
        },
        pincode_status() {
            if (this.add_id == 0 || this.add_id == undefined) {
                this.$toasted.global.error({
                    message: 'Please Select Shipping Address'
                });
            } else if (this.billing_id == 0 || this.billing_id == undefined) {
                this.$toasted.global.error({
                    message: 'Please Select Billing address'
                });
            } else if (this.cartCount.count == 0) {
                this.$toasted.global.error({
                    message: 'Your cart is empty please add some product'
                });
            } else {
                var self = this
                axios({
                    method: 'POST',
                    url: this.$root.URL_ROOT + 'api.php',
                    data: {
                        type: 'pincode_status',
                        pincode: self.selectedAddress.zipcode
                    }
                }).then(function (response) {
                    var data = response.data
                    if (data.status == 'success') {
                        self.orderGenerate()
                    }
                })
            }
        },
        createShipping() {
            var amount = 0
            var self = this
            var getcouponMoney = parseFloat(this.myCouponMoney)
            if (this.paymentMethod == 1) {
                self.paymenttype = "COD";
                // amount = parseFloat((this.cartCount.total_amount + this.shipping) - this.myCouponMoney);
                var amount = parseFloat(this.cartCount.total_amount + this.shipping - getcouponMoney) + parseFloat((this.cartCount.total_gst / 100) * this.cartCount.total_amount)
                // {{parseFloat(this.cartCount.total_amount+this.shipping-getcouponMoney) + parseFloat((this.cartCount.total_gst / 100)*this.cartCount.total_amount )}}
            }
            var oid = localStorage.getItem('oid')
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'api.php',
                data: {
                    type: 'testingShipping',
                    add_id: self.add_id,
                    awb_number: self.awbNumber,
                    oid: oid,
                    product: self.paymenttype,
                    qty: self.cartCount.count,
                    amount: amount
                }
            }).then(function (response) {
                var data = response.data
                if (data.status == 'success') {
                    self.$toasted.global.success({
                        message: 'Success Please wait...'
                    });
                    if (self.paymentMethod == 1) {
                        self.$router.push({
                            name: 'success_cod'
                        });
                    } else {
                        self.$router.push({
                            name: 'success'
                        });
                    }

                }

            })
        },
        applyCoupon() {
            var self = this
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'api.php',
                data: {
                    type: 'couponapply',
                    coupon: self.couponCode,
                    uid: self.uid
                }
            }).then(function (response) {
                var data = response.data
                if (data.status == "success") {
                    self.myCouponMoney = data.data
                    localStorage.setItem('coupon', data.data)
                    localStorage.setItem('coupon_name', self.couponCode)
                    self.$toasted.global.success({
                        message: data.msg
                    });
                } else {
                    self.$toasted.global.error({
                        message: data.msg
                    });
                }
            })
        },
        switchLoc() {
            var iv = this
            var vali = this.$refs.form.validate();
            vali.then(val => {
                    if (val == true) {
                        iv.save_address()
                    }
                }

            )
        },
        switchLocBilling() {
            var iv = this
            var vali = this.$refs.formBilling.validate();
            vali.then(val => {
                    if (val == true) {
                        iv.save_billing_address()
                    }
                }

            )
        },
        add_select() {
            if (this.add_id > 0) {
                this.dashboard_box = 'step-3';
            } else {
                this.$toasted.global.error({
                    message: "Please select address "
                });
            }
        },
        includeStripe(URL, callback) {
            let documentTag = document,
                tag = 'script',
                object = documentTag.createElement(tag),
                scriptTag = documentTag.getElementsByTagName(tag)[0];
            object.src = 'https://' + URL;
            if (callback) {
                object.addEventListener('load', function (e) {
                    callback(null, e);
                }, false);
            }
            scriptTag.parentNode.insertBefore(object, scriptTag);
        },
        makePayment: function (ev) {
            if (this.add_id == 0) {
                this.$toasted.global.error({
                    message: 'Please add address'
                });
            } else {
                var ev = this
                var getcouponMoney = parseFloat(this.myCouponMoney)
                if (this.cartCount.total_amount >= this.min) {
                var amount = parseFloat(this.cartCount.total_amount + this.shipping) - parseFloat((this.myCouponMoney / 100) * this.cartCount.total_amount)
                var shipping = this.shipping;
            } else {
                var amount = parseFloat(this.cartCount.total_amount) - parseFloat((this.myCouponMoney / 100) * this.cartCount.total_amount)
                var shipping = 0;
            }
            var oid = localStorage.getItem('oid');
            var uid = localStorage.getItem('user');
            document.getElementById("p_oid").value=oid;
           // document.getElementById("p_user").value=uid;
            document.getElementById("p_amount").value=amount;
            document.getElementById("pbtn").click();

                console.log('cxffsdf');
               /* var rzp1 = new Razorpay({
                    key: 'rzp_test_MBSCGzeRjYUhaT',
                    amount: amount * 100,
                    currency: "INR",
                    "handler": function (response) {
                        ev.pay_id = response.razorpay_payment_id
                        localStorage.setItem('successData', response)
                        ev.updatePayment(response);
                    },
                    "ondismiss": function () {
                        window.location.reload();
                    },
                    prefill: {},
                    notes: {
                        // address: this.address, //this.$store.state.profile.address
                    },
                    theme: {
                        color: "#00FFFF"
                    },
                    //order_id: 1,
                    redirect: false
                });
                rzp1.open(); */
            }

        },
        updatePayment(payment_detail) {
            var self = this;
            var oid = localStorage.getItem('oid');
            var uid = localStorage.getItem('user');
            var coupon_name = localStorage.getItem('coupon_name')
            var couponMoney = localStorage.getItem('coupon')
            axios.post(this.$root.URL_ROOT + "api.php", {
                type: 'payment_success',
                uid: uid,
                oid: oid,
                payment_detail: payment_detail,
                coupon_name: coupon_name,
                coupon: couponMoney,
            }).then(function (response) {
                var data = response.data
                if (data.status == 'success') {
                    self.generateAwbNumber()
                }
            });
        },
        goToTop() {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        },
        open_dashboard(vl) {
            this.dashboard_box = vl;
            window.scrollBy(100, 0);

        },
        open_dashboard2() {
            if (this.dashboard_box == 'step-1') {
                this.dashboard_box = 'step-3'
            } else {
                this.dashboard_box = 'step-1'
            }
        },
        userDetail() {
            var ev = this;
            axios({
                method: "POST",
                url: this.$root.URL_ROOT + "api.php",
                data: {
                    type: "userDetail",
                    uid: ev.uid
                },
            }).then(function (response) {
                var data = response.data;
                if (data.data) {
                    ev.userData = data.data
                } else {
                    ev.logout()
                    ev.$toasted.global.error({
                        message: 'Your Account is Deactivated'
                    })
                    setTimeout(function () {
                        ev.$router.push({
                            name: 'Home'
                        });
                    }, 1000);
                }
            });
        },
        orderGenerate() {
            var ev = this
            var paymentType = 'Online'
            if (this.paymentMethod == 1) {
                paymentType = 'COD'
            }
            var user_session = localStorage.getItem("user");
            var uid = "";
            if (user_session != null) {
                uid = user_session;
            } else {
                uid = null;
            }
            var gst = this.cartCount.total_gst;
            if (this.cartCount.total_amount >= this.min) {
                // var amount = parseFloat(this.cartCount.total_amount + this.shipping - this.myCouponMoney)
                // var amount = parseFloat(this.cartCount.total_amount + this.shipping) - parseFloat((this.myCouponMoney / 100)*this.cartCount.total_amount)
                var amount = (this.FinalTotalAmount).toFixed(0)
                var shipping = this.shipping;
            } else {
                var amount = parseFloat(this.cartCount.total_amount - this.myCouponMoney)
                var amount = parseFloat(this.cartCount.total_amount) - parseFloat((this.myCouponMoney / 100) * this.cartCount.total_amount)
                var shipping = 0;
            }
            // console.log(amount);
            axios({
                method: "POST",
                url: this.$root.URL_ROOT + "api.php",
                data: {
                    type: "order",
                    uid: uid,
                    address_id: ev.add_id,
                    billing_id: ev.billing_id,
                    total: amount,
                    payment_type: paymentType,
                    coupon: ev.myCouponMoney,
                    shipping_amount: shipping,
                    gst: gst,
                },
            }).then(function (response) {
                var data = response.data;
                if (data.status == "success") {
                    localStorage.setItem('oid', data.oid)
                    ev.isAdd = data.add_id
                    if (ev.paymentMethod == 2) {
                        ev.makePayment()
                    } else {
                        ev.updatePayment()
                    }
                    ev.open_dashboard("step-3");
                    ev.getAddress();
                }
            });
        },
        getAddress() {
            var ip_address = JSON.parse(localStorage.getItem("ip_address"));
            var user_session = JSON.parse(localStorage.getItem("user"));
            var uid = "";
            if (user_session != null) {
                uid = user_session;
            } else {
                uid = null;
            }
            var ev = this;
            axios({
                method: "POST",
                url: this.$root.URL_ROOT + "api.php",
                data: {
                    type: "getAllAddress",
                    uid: uid,
                    ip_address: ip_address,
                },
            }).then(function (response) {
                var data = response.data;
                if (data.status == "success") {
                    ev.addressDatas = data.data;
                    if (ev.addressDatas.length == 0) {
                        ev.dashboard_box = "step-2"
                    }
                    // ev.add_id = ev.addressDatas[0].add_id
                    // ev.billing_id = ev.addressDatas[0].add_id
                    ev.getSelectedAddress(ev.addressDatas[0].add_id);
                }
            });
        },
        save_address() {
            var ev = this;
            if (this.userAddress.add_title == "") {
                ev.$toasted.global.error({
                    message: "please enter FullName "
                });
            } else if (this.userAddress.mobile_no == "") {
                ev.$toasted.global.error({
                    message: "please enter mobile number "
                });
            } else if (this.userAddress.email == "") {
                ev.$toasted.global.error({
                    message: "please enter Email "
                });
            } else if (this.userAddress.add1 == "") {
                ev.$toasted.global.error({
                    message: "please enter address 1 "
                });
            } else if (this.userAddress.city == "") {
                ev.$toasted.global.error({
                    message: "please enter city "
                });
            } else if (this.userAddress.c_state == "") {
                ev.$toasted.global.error({
                    message: "please select state "
                });
            } else if (this.userAddress.add1 == "") {
                ev.$toasted.global.error({
                    message: "please Enter address line 1 "
                });
            } else if (this.userAddress.add2 == "") {
                ev.$toasted.global.error({
                    message: "please Enter address line 2"
                });
            } else if (this.userAddress.zipcode == "") {
                ev.$toasted.global.error({
                    message: "please Enter zipcode"
                });
            } else {
                var user_session = localStorage.getItem("user");
                var uid = "";
                if (user_session != null) {
                    uid = user_session;
                } else {
                    uid = null;
                }
                if (ev.userAddress.shippingBilling == true) {
                    ev.userAddress.add_type = 'Both'
                }
                // console.log(ev.userAddress.shippingBilling);
                axios({
                    method: "POST",
                    url: this.$root.URL_ROOT + "api.php",
                    data: {
                        type: "add_address",
                        uid: uid,
                        add_title: ev.userAddress.add_title,
                        city: ev.userAddress.city,
                        email: ev.userAddress.email,
                        mobile: ev.userAddress.mobile_no,
                        add1: ev.userAddress.add1,
                        add2: ev.userAddress.add2,
                        pincode: ev.userAddress.zipcode,
                        state: ev.userAddress.c_state,
                        country: ev.userAddress.country,
                        add_type: ev.userAddress.add_type,
                    },
                }).then(function (response) {
                    var data = response.data;
                    // console.log('add_address', response);
                    if (data.status == "success") {
                        ev.$toasted.global.success({
                            message: data.msg
                        });
                        ev.getShippingAddress()
                        ev.getBillingAddress()
                        ev.add_id = data.add_id
                        ev.isAdd = data.add_id
                        ev.open_dashboard("step-3");
                        ev.getAddress();
                    }
                });
            }
        },
        save_billing_address() {
            var ev = this;
            if (this.userAddressBilling.add_title == "") {
                ev.$toasted.global.error({
                    message: "please enter FullName "
                });
            } else if (this.userAddressBilling.mobile_no == "") {
                ev.$toasted.global.error({
                    message: "please enter mobile number "
                });
            } else if (this.userAddressBilling.email == "") {
                ev.$toasted.global.error({
                    message: "please enter Email "
                });
            } else if (this.userAddressBilling.add1 == "") {
                ev.$toasted.global.error({
                    message: "please enter address 1 "
                });
            } else if (this.userAddressBilling.city == "") {
                ev.$toasted.global.error({
                    message: "please enter city "
                });
            } else if (this.userAddressBilling.c_state == "") {
                ev.$toasted.global.error({
                    message: "please select state "
                });
            } else if (this.userAddressBilling.add1 == "") {
                ev.$toasted.global.error({
                    message: "please Enter address line 1 "
                });
            } else if (this.userAddressBilling.add2 == "") {
                ev.$toasted.global.error({
                    message: "please Enter address line 2"
                });
            } else if (this.userAddressBilling.zipcode == "") {
                ev.$toasted.global.error({
                    message: "please Enter zipcode"
                });
            } else {
                var user_session = localStorage.getItem("user");
                var uid = "";
                if (user_session != null) {
                    uid = user_session;
                } else {
                    uid = null;
                }

                axios({
                    method: "POST",
                    url: this.$root.URL_ROOT + "api.php",
                    data: {
                        type: "add_address",
                        uid: uid,
                        add_title: ev.userAddressBilling.add_title,
                        city: ev.userAddressBilling.city,
                        email: ev.userAddressBilling.email,
                        mobile: ev.userAddressBilling.mobile_no,
                        add1: ev.userAddressBilling.add1,
                        add2: ev.userAddressBilling.add2,
                        pincode: ev.userAddressBilling.zipcode,
                        state: ev.userAddressBilling.c_state,
                        country: ev.userAddressBilling.country,
                        add_type: 'Billing',
                    },
                }).then(function (response) {
                    var data = response.data;
                    // console.log('add_address', response);
                    if (data.status == "success") {
                        ev.$toasted.global.success({
                            message: data.msg
                        });
                        ev.getShippingAddress()
                        ev.getBillingAddress()
                        ev.add_id = data.add_id
                        ev.isAdd = data.add_id
                        ev.open_dashboard("step-3");
                        ev.getAddress();
                    }
                });
            }
        },
        getState() {
            var ev = this;
            axios({
                method: "POST",
                url: this.$root.URL_ROOT + "api.php",
                data: {
                    type: "getState",
                },
            }).then(function (response) {
                var data = response.data;
                ev.stateData = data;
            });
        },
        checked_add(id) {
            this.aid = id;
            this.getSelectedAddress();
            this.address_box = true;
        }
    },
};
</script>
